import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

const TermsandCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div className='bg-[#f5f5f5] pb-5'>
        <div className='px-5 py-14 md:py-20  bg-[#797979] text-center'>
          <h3 className='text-white text-[28px] md:text-[40px] font-semibold'>Terms & Conditions</h3>
          <div className='text-center text-sm lg:text-base text-yellow-400'><span><Link to="/" className='text-gray-300'>Home /</Link>  Terms and Condition</span></div>
        </div>

        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[20px] md:text-[25px] font-semibold'>Terms and Conditions</div>
            <p className='mt-3'>These terms and conditions (“Terms”) govern the use of the <Link className=' text-blue-500' to="/">divyasaionewaydroptaxi.com</Link> , including the services offered through Divya Sai One way Drop Taxi. By accessing or using the Services, you (“User”) agree to be bound by these Terms. If you do not agree to these Terms, do not use the Services</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Service Description</div>
            <p className='mt-3'>The Services offered through our website include booking and drop-off taxi services, as well as related services offered by Divya Sai One way Drop Taxi.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>User Eligibility</div>
            <p className='mt-3'>To use the Services, Users must be at least 18 years old and have the legal capacity to enter into a contract. By using the Services, Users represent and warrant that they meet this eligibility requirement.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>User Obligations</div>
            <p className='mt-3'>User Obligations</p>
            <ul className='mt-2'>
              <li>● Providing accurate and complete information when using the Services</li>
              <li>● Keeping their information up to date</li>
              <li>● Complying with all applicable laws and regulations when using the Services</li>
            </ul>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Privacy Policy</div>
            <p className='mt-3'>Divya Sai One way Drop Taxi's Privacy Policy, which is incorporated into these Terms by reference, governs the collection and use of the User’s personal information. By using the Services, Users agree to the terms of the Privacy Policy.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Cancellation Policy</div>
            <p className='mt-3'>Divya Sai One way Drop Taxi may cancel User’s reservation at any time and for any reason, including but not limited to, if Divya Sai One way Drop Taxi determines that User has violated these Terms. User may cancel their reservation at any time prior to pickup. The refund of booking amount would be made, subject to deduction of 10% of cab booking as cancellation charges.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Disclaimer of Warranties</div>
            <p className='mt-3'>The Services are provided “as is” and “as available” without warranty of any kind, either express or implied. Divya Sai One way Drop Taxi makes no representations or warranties, express or implied, as to the accuracy, completeness, reliability, suitability, or availability of respect to the Services or the information, products, services, or related graphics contained in the Services for any purpose.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Limitation of Liability</div>
            <p className='mt-3'>Divya Sai One way Drop Taxi will not be liable for any damages of any kind arising from the use of the Services, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Indemnification</div>
            <p className='mt-3'>Users agree to indemnify and hold Divya Sai One way Drop Taxi and its affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of User’s use of the Services, violation of these Terms, or violation of any rights of another.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Governing Law</div>
            <p className='mt-3'> These Terms will be governed by and construed in accordance with the laws of the jurisdiction, without giving effect to its conflict of laws provisions.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Entire Agreement</div>
            <p className='mt-3'>These Terms, together with the Privacy Policy, constitute the entire agreement between User and Divya Sai One way Drop Taxi with respect to the use of the Services.</p>
          </div>
        </div>
        <div className='mt-6 container mx-auto shadow-xl '>
          <div className=' bg-white rounded py-3 px-4'>
            <div className='text-black text-[18px] md:text-[20px] font-semibold'>Reach Us</div>
            <p className='mt-3'>If you have any queries related to the terms and conditions or are quite complicated to access this website, please contact us at <a className=' text-blue-500' href="mailto:divyasaionewaydroptaxi@gmail.com">divyasaionewaydroptaxi@gmail.com</a> or give us a call at  +91 9488180373.</p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default TermsandCondition