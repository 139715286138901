import React, { useEffect, useRef, useState } from 'react'
import selct_car from "../assets/images/selct_car.svg";
import select_car from "../assets/images/selct_car.svg";
import sedan from "../assets/images/sedan.png";
import etios from "../assets/images/etios.png";
import suv from "../assets/images/suv.png";
import innova from "../assets/images/innova.png";
import { useForm } from 'react-hook-form';
import { Google, GoogleMap, useLoadScript, StandaloneSearchBox, GoogleApiWrapper, DistanceMatrixService } from '@react-google-maps/api';
import axios from 'axios';
import { AiOutlineCloseCircle } from "react-icons/ai";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { netbixTelegramChatId, telegramApi, telegramChatId, netbixTelegramApi } from "../config/config";

const libraries = ['places'];

const OneWay = () => {
    const [selectedDate, setSelectedDate] = useState('');
    const [driverBeta, setDriverBeta] = useState(400);
    const [carList, setCarList] = useState(false)
    const [listCars, setListCars] = useState({ car_amount: 0 })
    const [carReq, setCarReq] = useState(false)
    const [distanceResult, setDistanceResult] = useState({ org_distance: null, org_duration: null });
    const [google, setGoogle] = useState(null);
    const [whatsaappMsg, setWhatsaappMsg] = useState('');

    const [popup, setPopup] = useState(false);

    const [popupDis, setPopupDis] = useState(0);
    const [popupDur, setPopupDur] = useState('');
    const [popupCar, setPopupCar] = useState('');
    const [popupRate, setPopupRate] = useState(0);
    const [totRate, setTotalRate] = useState(0);
    const [loadingButton, setBoadingButton] = useState(true);
    function openCars() {
        setCarList(!carList);
    }
    const searchBox = useRef(null);

    const formRef = useRef(null);

    function sendWhatsapp() {
        document.body.classList.remove('dis_scroll');
        setPopup(false);
        window.open("https://wa.me/+919488180373?text=" + whatsaappMsg);
    }

    const handlePlacesChanged = () => {
        const places = searchBox.current.getPlaces();
        console.log(places);
    };

    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: 'AIzaSyCYfVYTCGKRhBNN5v6uIqb3C477IWcVcCU',
    //     libraries,
    // });

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
    } = useForm();



    useEffect(() => {
        if (window.google) {
            setGoogle(window.google);
        }
    }, []);


    const onSubmit = async (data) => {

        var day = selectedDate.getDate();
        console.log(day);
        var month = selectedDate.getMonth() + 1;
        console.log(month);

        var year = selectedDate.getFullYear();
        console.log(year);
        var formattedDate = day + '/' + month + '/' + year;
        console.log(formattedDate);

        if (listCars.car_amount === 0) {
            setCarReq(true);
            return;
        } else {
            setCarReq(false);
        }
        if (listCars.car_amount === "0") {
            setCarReq(true);
        }
        if (listCars.car_amount !== "0" && 
        data.drop_add !== '' && 
        data.full_name !== '' && 
        data.mob_number !== '' && 
        data.pick_up_add !== '' && 
        selectedDate !== '' && 
        data.pickup_time !== '') {
            setBoadingButton(true);
            if (data.drop_add === data.pick_up_add) {
                return alert("Please select Different Places");
            }
            if (!google) {
                alert('Google Maps API is not loaded yet.');
                return;
            }
            setPopupCar(listCars.text);

            const distanceMatrixService = new google.maps.DistanceMatrixService();

            const distanceMatrixOptions = {
                origins: [data.pick_up_add],
                destinations: [data.drop_add],
                travelMode: 'DRIVING'
            };

            await distanceMatrixService.getDistanceMatrix(distanceMatrixOptions, (response, status) => {
                if (status === 'OK' && response.destinationAddresses[0].length !== 0 && response.originAddresses[0].length !== 0) {
                    if (response.rows[0].elements[0].distance.text || response.rows[0].elements[0].duration.text) {
                        var org_distance = response.rows[0].elements[0].distance.text;
                        var org_duration = response.rows[0].elements[0].duration.text;
                        setPopupDur(org_duration);
                        setPopupDis(org_distance);
                        setDistanceResult({ org_distance, org_duration });

                        if (org_distance != null && org_duration != null) {
                            var one_way_rate = parseInt(org_distance) * parseInt(listCars.car_amount);
                            setTotalRate(parseInt(org_distance) * parseInt(listCars.car_amount));
                            if (parseInt(org_distance) <= 130) {
                                one_way_rate = 130 * parseInt(listCars.car_amount);
                                setTotalRate(130 * parseInt(listCars.car_amount));
                            }
                            var front_url = "https://www.divyasaionewaydroptaxi.com/";

                            setPopupRate((parseInt(one_way_rate) + driverBeta))

                            setWhatsaappMsg("Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0AName : " + data.full_name + " ,%0A%0AMobile Number  : +91" + data.mob_number + " ,%0A%0APickup Location  : " + data.pick_up_add + " ,%0A%0ADrop Location : " + data.drop_add + ",%0A%0ATrip Type: One Way ,%0A%0APickup Date/Time: " + formattedDate + " " + data.pickup_time + ",%0A%0ATotal KM : " + org_distance + " ,%0A%0ADuration : " + org_duration + " ,%0A%0ARate Per KM: " + listCars.car_amount + " ₹,%0A%0ADriver Beta : " + driverBeta + " ₹  ,%0A%0ARate for Oneway : " + one_way_rate + "  ₹,%0A%0ATotal Trip Fare :" + (parseInt(one_way_rate) + driverBeta) + " ₹ ,%0A%0AToll, parking, permit extra %0A%0AFor any questions please contact +919488180373 %0A%0A" + front_url)
                            var message = "Website Enquiry\n******* *******\n\nYour Booking Details:\n\nName : " + data.full_name + " ,\n\nMobile Number  : +91" + data.mob_number + " ,\n\nPickup Location  : " + data.pick_up_add + " ,\n\nDrop Location : " + data.drop_add + ",\n\nTrip Type: One Way ,\n\nPickup Date/Time: " + formattedDate + " " + data.pickup_time + ",\n\nTotal KM : " + org_distance + " ,\n\nDuration : " + org_duration + " ,\n\nRate Per KM: " + listCars.car_amount + " ₹,\n\nDriver Beta : " + driverBeta + " ₹  ,\n\nRate for Oneway : " + one_way_rate + "  ₹,\n\nTotal Trip Fare :" + (parseInt(one_way_rate) + driverBeta) + " ₹ ,\n\nToll, parking, permit extra \n\nFor any questions please contact +919488180373 \n\n" + front_url;
                            try {
                                const response = axios.post(
                                    `https://api.telegram.org/bot${telegramApi}/sendMessage`,
                                    {
                                        chat_id: telegramChatId,
                                        text: message,
                                    }
                                );
                                if (true) {
                                    setListCars({
                                        text: '',
                                        imageSrc: '',
                                        car_amount: 0
                                    })
                                    setBoadingButton(false);
                                    formRef.current.reset();
                                    document.body.classList.add('dis_scroll');
                                    setPopup(true);
                                    setSelectedDate(null);
                                }
                            } catch (error) {
                                setBoadingButton(false);
                                alert('Please try again')
                                console.error('Error sending Telegram message:', error);
                                // Handle the error
                            }
                            try {
                                axios.post(
                                    `https://api.telegram.org/bot${netbixTelegramApi}/sendMessage`,
                                    {
                                        chat_id: netbixTelegramChatId,
                                        text: message,
                                    }
                                );
                            } catch (error) {
                                console.error("Error sending Telegram message:", error);
                                // Handle the error
                            }
                        } else {

                            alert("Please Try again!");
                        }
                    }
                } else {
                    console.error('Error:', status);
                }
            });
        };
    }
    function popUpClose() {
        document.body.classList.remove('dis_scroll');
        setPopup(false);
    }
    const selectCar = (text, imageSrc, car_amount, carDriverBeta) => {
        setListCars({
            text,
            imageSrc,
            car_amount
        })
        setDriverBeta(carDriverBeta);
        setCarList(false);
    };

    return (
        <div className='mt-6'>
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>

                <div className='grid grid-cols-1 md:grid-cols-2'>
                    <div className='p-3'>
                        <input type="text" className='w-full py-3 px-4 border border-gray-600' placeholder='Full Name' name='full_name' {...register('full_name', { required: true })} />
                        {errors.full_name && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Name is required.</span>}
                    </div>
                    <div className='p-3'>
                        <input type="number" 
                        className='w-full py-3 px-4 border border-gray-600' 
                        placeholder='Mobile number' name='mob_number' 
                        {...register("mob_number",
                        {
                            required: "Mobile Number should be 10 digits", minLength: { value: 10, message: "Requied 10 digit" },
                            maxLength: { value: 10, message: "Requied only 10 digit" }
                        })}
                />
                        {errors.mob_number && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Mobile Number is required.</span>}

                    </div>
                    <div className='p-3'>
                        <StandaloneSearchBox
                            onLoad={(ref) => (searchBox.current = ref)}
                            onPlacesChanged={handlePlacesChanged}
                        >
                            <input type="text" className='w-full py-3 px-4 border border-gray-600' placeholder='Pick up address' name='pick_up_add' {...register('pick_up_add', { required: true })} />
                        </StandaloneSearchBox>
                        {errors.pick_up_add && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Pick up Address is required.</span>}
                    </div>
                    <div className='p-3'>
                        <StandaloneSearchBox
                            onLoad={(ref) => (searchBox.current = ref)}
                            onPlacesChanged={handlePlacesChanged}
                        >
                            <input type="text" className='w-full py-3 px-4 border border-gray-600' placeholder='Drop off address' name='drop_add' {...register('drop_add', { required: true })} />
                        </StandaloneSearchBox>
                        {errors.drop_add && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Drop Adress is required.</span>}
                    </div>
                    <div className='p-3'>
                        <DatePicker
                            selected={selectedDate}
                            onChange={date => setSelectedDate(date)}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            className='w-full py-3 px-4 border border-gray-600'
                            placeholderText="Pick up Date"
                            name='pickup_date'
                        />
                        <input type="hidden" 
                        value={selectedDate ? selectedDate : ' '} name='pickup_dates'    {...register('pickup_dates', { required: true })} />
                        {!selectedDate && errors.pickup_dates && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white border border-gray-600'>Pickup Date is required.</span>}

                    </div>
                    <div className='p-3'>
                        <select className="w-full py-3 px-4 border border-gray-600" id="pickup_time" name="pickup_time" {...register('pickup_time', { required: true })}>
                            <option value="">Pickup Time</option>

                            <option value="12:00 AM">12:00 AM</option>

                            <option value="12:30 AM">12:30 AM</option>

                            <option value="01:00 AM">01:00 AM</option>

                            <option value="01:30 AM">01:30 AM</option>

                            <option value="02:00 AM">02:00 AM</option>

                            <option value="02:30 AM">02:30 AM</option>

                            <option value="03:00 AM">03:00 AM</option>

                            <option value="03:30 AM">03:30 AM</option>

                            <option value="04:00 AM">04:00 AM</option>

                            <option value="04:30 AM">04:30 AM</option>

                            <option value="05:00 AM">05:00 AM</option>

                            <option value="05:30 AM">05:30 AM</option>

                            <option value="06:00 AM">06:00 AM</option>

                            <option value="06:30 AM">06:30 AM</option>

                            <option value="07:00 AM">07:00 AM</option>

                            <option value="07:30 AM">07:30 AM</option>

                            <option value="08:00 AM">08:00 AM</option>

                            <option value="08:30 AM">08:30 AM</option>

                            <option value="09:00 AM">09:00 AM</option>

                            <option value="09:30 AM">09:30 AM</option>

                            <option value="10:00 AM">10:00 AM</option>

                            <option value="10:30 AM">10:30 AM</option>

                            <option value="11:00 AM">11:00 AM</option>

                            <option value="11:30 AM">11:30 AM</option>

                            <option value="12:00 PM">12:00 PM</option>

                            <option value="12:30 PM">12:30 PM</option>

                            <option value="01:00 PM">01:00 PM</option>

                            <option value="01:30 PM">01:30 PM</option>

                            <option value="02:00 PM">02:00 PM</option>

                            <option value="02:30 PM">02:30 PM</option>

                            <option value="03:00 PM">03:00 PM</option>

                            <option value="03:30 PM">03:30 PM</option>

                            <option value="04:00 PM">04:00 PM</option>

                            <option value="04:30 PM">04:30 PM</option>

                            <option value="05:00 PM">05:00 PM</option>

                            <option value="05:30 PM">05:30 PM</option>

                            <option value="06:00 PM">06:00 PM</option>

                            <option value="06:30 PM">06:30 PM</option>

                            <option value="07:00 PM">07:00 PM</option>

                            <option value="07:30 PM">07:30 PM</option>

                            <option value="08:00 PM">08:00 PM</option>

                            <option value="08:30 PM">08:30 PM</option>

                            <option value="09:00 PM">09:00 PM</option>

                            <option value="09:30 PM">09:30 PM</option>

                            <option value="10:00 PM">10:00 PM</option>

                            <option value="10:30 PM">10:30 PM</option>

                            <option value="11:00 PM">11:00 PM</option>

                            <option value="11:30 PM">11:30 PM</option>
                        </select>
                        {errors.pickup_time && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Pickup Time is required.</span>}
                    </div>
                </div>
                <div className='p-3'>
                    <input type="hidden" name='car_amount' value={listCars.car_amount != 0 ? listCars.car_amount : 0} />
                    <div className='p-3 px-4 bg-white cursor-pointer relative border border-gray-600' onClick={openCars} >{listCars.text ? listCars.text : 'Select car Type'} <img src={listCars.imageSrc ? listCars.imageSrc : selct_car} alt="select car" className=' w-[35px] h-auto absolute top-1/2 right-4 -translate-y-1/2' /></div>
                    <ul className={`bg-white border ${carList ? '' : 'hidden'}`}>
                        <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('SEDAN SWIFT', sedan, 14, 400)}>SEDAN SWIFT <img src={sedan} alt="sedan" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' /></li>
                        <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('SEDAN ETIOS', etios, 14, 400)}>SEDAN ETIOS <img src={etios} alt="etios" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' /></li>
                        <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('SUV INNOVA', innova, 19, 400)}>SUV INNOVA <img src={innova} alt="innova" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' /></li>
                        <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('SUV XYLO', suv, 19, 400)}>SUV XYLO<img src={suv} alt="suv" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' /></li>
                    </ul>
                    {carReq ? <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Car is required.</span> : ''}
                </div>
                <div className='text-center mt-5'>
                    <button className='bg-[#262262] mt-4 md:mt-6 text-white py-3 px-6  text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase hover:bg-black transition-all ease-linear'>Get Estimation</button>
                </div>
            </form>
            {popup ? <div className='fixed top-0 left-0  h-full w-full z-[100]'>
                <div className='w-[90%] md:w-3/5 min-h-[200px] bg-gray-400 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute p-5'>
                    <span className='fixed top-2 right-2 cursor-pointer' onClick={popUpClose}>
                        <AiOutlineCloseCircle className='text-[20px]' />
                    </span>
                    <h2 className='text-[30px]'>Trip Estimation <span className='text-[#262262]'> Rs.{popupRate}</span></h2>
                    <div>
                        <div className='grid grid-cols-2 py-3 mt-4 '>
                            <div className='pl-2 border-b-2 border-t-2 border-l-2 py-3'>
                                Total Distance :
                            </div>
                            <div className='pr-2 border-b-2  border-t-2 border-r-2 border-l-2 px-2 py-3'>
                                {popupDis}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Total Duration
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                {popupDur}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Selected Car Type
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                {popupCar}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Driver Allowance :
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                Included
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Total Amount :
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                Rs. {totRate}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Driver Beta :
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                Rs. {driverBeta}
                            </div>
                        </div>
                        <div className='mt-4 text-center'>
                            <button onClick={sendWhatsapp} className='bg-[#fccf11] py-3 text-white px-6 rounded-md'>Book Now</button>
                        </div>
                    </div>
                </div>
            </div> : ''}

        </div>
    )
}

export default OneWay