import WhatsappComponent from "./WhatsappComponent";
import PhoneComponent from "./components/PhoneComponent";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsandCondition from "./pages/TermsandCondition";
import Header from "./components/Header";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <>
    <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsandCondition />}
          ></Route>
        </Routes>
        <Footer />
        <WhatsappComponent />
        <PhoneComponent />
      </BrowserRouter>
    </>
  );
}

export default App;
