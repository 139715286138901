import React from 'react'
import about from "../assets/about.jpg";
const About = () => {
    return (
        <div className='bg-[#ffc9cb]' id='about'>
            <div className='container py-5 mx-auto px-0 sm:px-4 md:px-8 lg:px-16'>
                <div className='block lg:flex py-14 gap-x-28 items-center'>
                    <div className='w-full lg:w-auto'>
                        <div>
                            <img src={about} alt="about" className='mx-auto w-[500px] car_outline' />
                        </div>
                    </div>
                    <div className='mt-20 lg:mt-0'>
                        <h4 className='text-black text-5xl font-medium'>About</h4>
                        <div className='text-[#262262] text-3xl font-bold mt-4'>Welcome to Divya Sai One Way Drop Taxi</div>
                        <div className=' text-black opacity-60 text-xl mt-5'>We Provide 24x7 One way Drop Service in Tamilnadu. Divya Sai One Way Drop Taxi is one of the best cab service in Chennai, provides its services between various locations across Tamilnadu at best price. We provides safest journey in Tamil Nadu</div>
                        <div className=' '>
                            <h3 className=' font-semibold text-lg mt-3'>Best Drivers</h3>
                            <p className='pl-4 mt-2'>We have drivers with more than 15 years of experience in this driving field . so that we ensure customers to have pleasant journey.</p>
                            <h3 className=' font-semibold text-lg mt-3'>24x7 Call Support</h3>
                            <p className='pl-4 mt-2'>We have 24x7 Customer Support. Our Support Team will assist customers if they need any kind of support</p>
                            <h3 className=' font-semibold text-lg mt-3'>Safe and Secure</h3>
                            <p className='pl-4 mt-2'>Divya Sai One way Drop Taxi always provides safest and secure journey to the customers</p>
                            <h3 className=' font-semibold text-lg mt-3'>Neat and Clean</h3>
                            <p className='pl-4 mt-2'>We always maintain our cabs with neat and clean.So that Customers always feel hygenic through out the journey</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About